import React, { useState } from 'react'
import './sub-menu.sass'

function SubMenu (props) {
  const [menuSelected, setMenuSelected] = useState(0)

  const menu = props.names.map((el, i) =>
    <button
      onClick={(e) =>{ setMenuSelected(i)}}
      className="SubMenu__names-button"
      key={el} >
        <p>{el.toUpperCase()}</p>
        <div className={`SubMenu__names-button-underline ${menuSelected === i ? 'SubMenu__names-button-underline-selected' : null}`}></div>
    </button>
  )

  return (
    <div className="SubMenu">
      <div className="SubMenu__names">{menu}</div>
      {props.items[menuSelected]}
    </div>
  )
}

export default SubMenu
