import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/layout'
import SEO from 'components/seo/seo'
import LayoutSidePages from 'components/layout/side-pages'
import DynamicBlocks from 'components/dynamic-blocks/dynamic-blocks'
import SubMenu from 'components/sub-menu/sub-menu'
import Image from 'components/image/image'
import RequestDemo from 'components/request-demo/request-demo'
import GetInTouch from 'components/get-in-touch/get-in-touch'
import './getting-started.sass'

const GettingStartedNav = (guideType, setGuideType) => (
  <React.Fragment>
    <h1 className="h2--new pb-5">Getting <br/> started Guide</h1>

    <div className="d-flex">
      <h4
        className={`k-pointer mr-5 mb-0 ${guideType === 'noCode' ? "GettingStartedGuide__selected" : null}`}
        onClick={() => {setGuideType('noCode') }}>
          Basic
      </h4>
      <h4
        className={`k-pointer mb-0 ${guideType === 'Advanced' ? "GettingStartedGuide__selected" : null}`}
        onClick={() => {setGuideType('Advanced') }}>
          Advanced
      </h4>
    </div>
  </React.Fragment>
)

const GettingStartedAutoMLSection = (guideType, {auto_ml}) => (
  <section className="GettingStartedGuide__autoML pb-3 k-bg--grey">
  <div className="container-fluid">
    {/* Background graphic & spinner overlay */}
    <Image image_url="icons/icon-full-autoML-missing-spinner.svg"
           className="m-5 GettingStartedGuide__autoML-full-no-spinner"
           alt={'AutoML AI brain image'} />


    <Image image_url="icons/icon-autoML-spinner.svg"
           className="m-5 GettingStartedGuide__autoML-spinner"
           alt={'AutoML AI brain spinner image'} />

    {/* Default graphic no spinner */}
    <Image image_url="icons/icon-full-autoML-default.svg"
           className="m-md-2 p-md-4 GettingStartedGuide__autoML-full-default"
           alt={'AutoML AI brain image'} />

    <h2 className="h2 text-center">{auto_ml.title}</h2>
    <div
      dangerouslySetInnerHTML={{
      __html: guideType === 'noCode' ? auto_ml.subtext_nocode : auto_ml.subtext_advanced
      }} />
  </div>
</section>
)

const noCodeAndAdvancedJSX = (d, guideType) => {
  if (guideType === 'noCode') {
    return (
      <React.Fragment>
        <DynamicBlocks data={d.sections_nocode.slice(0, 2)} />
        {GettingStartedAutoMLSection(guideType, d)}
        <DynamicBlocks data={d.sections_nocode.slice(2)} />
      </React.Fragment>
    )
  } else if (guideType === 'Advanced') {
    return (
      <React.Fragment>
        <DynamicBlocks data={d.sections_advanced.slice(0, 3)} />
        {GettingStartedAutoMLSection(guideType, d)}
        <DynamicBlocks data={d.sections_advanced.slice(3)} />
        <SubMenu
          names={['Python', 'R']}
          items={[
            <div>
              <Image image_url="content/gs-python-code/gs-python-code.png"
                     className="GettingStartedGuide__code"
                     alt={`Python code snippet for Kortical platform`}/>
              <DynamicBlocks data={d.section_snippet}/>
            </div>,
            <div>
              <Image image_url="content/gs-R-code/gs-R-code.png"
                      className="GettingStartedGuide__code"
                      alt={`R code snippet for Kortical platform`}/>
              <DynamicBlocks data={d.section_snippet}/>
            </div>
        ]}/>
      </React.Fragment>
    )
  }
}

const GettingStartedGuide = ({ data }) => {
  const d = data.gettingStartedYaml
  const [guideType, setGuideType ] = useState('noCode')

  return (
    <Layout bodyClass="k-reverse-header">
      <SEO
        title="Getting started with Kortical | Build 1000’s ML models in code, fast"
        metaTitle={d.meta.title}
        keywords={d.meta.keywords}
        description={d.meta.description}
        image={d.meta.image} />

      <div className="GettingStartedGuide">
          <div className="z-index-top">
            <LayoutSidePages background="k-bg--grey">
              {GettingStartedNav(guideType, setGuideType)}
            </LayoutSidePages>
            {noCodeAndAdvancedJSX(d, guideType)}
          </div>
      </div>

      <RequestDemo/>
      <GetInTouch/>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    gettingStartedYaml {
      meta {
        title
        keywords
        image
        description
      }
      section_snippet {
        number
        title
        subtext
        component
        classes {
          text_block
          number_style
        }
      }
      auto_ml {
        title
        subtext_nocode
        subtext_advanced
      }
      sections_nocode {
        number
        title
        subtext
        component
        image
        image_alt
        classes {
          container
          text_block
          number_style
        }
      }
      sections_advanced {
        number
        title
        subtext
        component
        image
        image_alt
        classes {
          container
          text_block
          number_style
        }
      }
    }
  }
`

export default GettingStartedGuide