import React from 'react'
import Image from 'components/image/image'
import './request-demo.sass'

export const RequestDemo = () => {
  return (
    <div className="RequestDemo k-bg--grey ">
      <Image image_url="layout/bg-demo-stripes.svg" alt="background hexagon stripes pattern" className="RequestDemo__background--stripes"/>
      <div className="container-fluid text-center">
        <Image image_url="layout/bg-diamond.svg" alt="background diamond pattern empty big" className="RequestDemo__background--big"/>
        <Image image_url="layout/bg-diamond.svg" alt="background diamond pattern empty small" className="RequestDemo__background--small"/>

        <h2>Request a demo in the form below</h2>
        <p>A helpful Kortical team member will reach out to set up a time and find out a little about your challenges, usually same day.</p>
        <p>Book in a demo / hands on session with your data or Kaggle datasets.</p>
      </div>
    </div>
  )
}

export default RequestDemo

